<template>
  <section class="dtc-main-section">
    <div>
      <b-jumbotron style="margin-top:16vh">
        <template #header>啟動註冊帳號</template>

        <template #lead>
          啟動情況:
          <span v-if="registerResult" class="success-word">
            您的註冊帳號已啟動成功</span
          >
          <span v-else class="fail-word">註冊帳號啟動失敗</span>
        </template>

        <hr class="my-4" />

        <p>
          Activate registered account
        </p>

        <b-button variant="primary" @click="$router.push('/login')"
          >返回首頁</b-button
        >
      </b-jumbotron>
    </div>
  </section>
</template>

<script>
import queryString from "query-string";
const rowObj = {};
const rows = [
  "業務費",
  "-公費生受領待遇補助費",
  "設備費",
  "",
  "",
  "小計",
  "餘 (絀) 數",
];
//年月日
export default {
  name: "registerCondition",
  data() {
    return {
      registerResult: false,
    };
  },
  methods: {
    async getData() {
      const { resu, drowssap } = queryString.parse(location.href.split("?")[1]);
      const url = `SysUser/RegActive?resu=${resu}&drowssap=${drowssap}`;

      try {
        const res = await window.axios.get(url);
        if (res.includes("註冊帳號已啟動")) {
          this.registerResult = true;
        } else {
          this.registerResult = false;
        }
      } catch (e) {
        this.registerResult = false;
        this.$bvToast.toast(e + ``, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
  },
  async mounted() {
    await this.getData();
  },
};
</script>

<style scoped lang="scss">
.dtc-main-section {
  padding: 80px;
  // background: #deeaf1;
  // background: #c0dffe;
  // height: 92.7vh;
  // width: 100vw;
  // overflow: hidden;
  // position: absolute;
}

.container {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #f5f6f8;
}
.bg-line,
.bg-line1,
.bg-line2 {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #f8f9ff3d;
  top: 0;
}
.bg-line1 {
  -webkit-clip-path: polygon(
    -57% 57%,
    8% -78%,
    -195% -105%,
    73% 142%,
    91% 103%
  );
  clip-path: polygon(-57% 57%, 8% -78%, -195% -105%, 73% 142%, 91% 103%);
}
.bg-line2 {
  -webkit-clip-path: polygon(68% 100%, 75% -10%, 100% 0%, 100% 60%, 70% 100%);
  clip-path: polygon(68% 100%, 75% -10%, 100% 0%, 100% 60%, 70% 100%);
}

.my-dark {
  > div {
    // background: var(--gray);
    background: #125fa3;
    color: white;
  }
}
.dtc-link {
  color: var(--primary);
  cursor: pointer;
}
.search-block {
  width: 800px;
  height: 377px;
  margin: 23vh auto 0 auto;
  background: #fbfcf9;
  // border: 1px solid #bdbdbd;
  border-radius: 8px 8px 0 0;
  .big-title {
    background: #0379fd;
    padding: 5px;
    color: #fff;
    border-radius: 6px 6px 0 0;
  }
  .main-content {
    padding: 0 20px;
    .row-content {
      margin-top: 12px;
    }
  }
}

.input-group-text {
  width: 120px;
  background: #0379fd;
  color: #fff;
}
.success-word {
  color: #f79400de;
  font-weight: 600;
}
.fail-word {
  color: red;
  font-weight: 600;
}
</style>
